import api from "../../../utils/api"
const apiUrl = process.env.REACT_APP_MOCK_RESUME_URL;

export const getResumeData = async(page  , limit ,  search) => {
  try {
    const res = await api.get(
      `/transactions?payment_for=RESUME&sortBy=created_at&orderBy=desc&page=${page}&limit=${limit}&search=${search ? search : ""}`,
      { baseURL:  apiUrl}

    );
    return res;
  } catch (err) {
    return err;
  }
}