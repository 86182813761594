import React from "react";
import { useState, useEffect } from "react";
// import dummyData from "./DummyUser";
import { getInterviewData } from "./Helper";
import { TablePagination } from "@mui/material";
import { useQueryParams } from "../../hooks";
import { useDebounce } from "../../hooks";
import { useOutletContext } from "react-router-dom";
import { AttachMoney, CurrencyRupee } from '@mui/icons-material';


export const MockInterviewList = () => {
  const [userData, setUserData] = useState([]);
  const { getParam, setMultiParam } = useQueryParams()
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [clientsPage, setClientsPage] = useState(parseInt(currentPage) || 0);
  const [clientsLimit, setClientsLimit] = useState(parseInt(currentLimit) || 10);
  const [userTotal, setUserTotal] = useState(0);
  const [search, setSearch] = useState('');
  const debounceSearch = useDebounce(search, 1000);
  const context = useOutletContext();

  const handleChangePage = (e, newPage) => {
    setClientsPage(newPage);
    fetchInterviewData(newPage, clientsLimit);
  };

  const handleChangeRowsPerPage = (event) => {
    setClientsLimit(parseInt(event.target.value, 10));
    setClientsPage(0);
    fetchInterviewData(0, parseInt(event.target.value, 10));
  };

  const fetchInterviewData = (page, limit) => {
    getInterviewData(page + 1, limit).then((data) => {
      setUserData(data.data.result);
      setUserTotal(data.data.meta.total)
    }).catch((err) => console.log(err))
  }

  useEffect(() => {
    context(["Dashboard", "Mock Interview"]);
    getInterviewData(clientsPage + 1, clientsLimit, debounceSearch).then((data) => {
      setUserData(data.data.result);
      setUserTotal(data.data.meta.total)
    }).catch((err) => console.log(err))
  }, [debounceSearch])

  useEffect(() => {
    setMultiParam({ "page": clientsPage, "pageSize": clientsLimit });
  }, [clientsPage, clientsLimit])


  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Mock Interviews
        </h3>
        <input
          type="text"
          id="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          className="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      </div>
      <br />
      <div className="overflow-x-auto relative ">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                Sr.
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Name
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Email ID
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Username
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Date of  Purchase
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                No. of Credits Purchased
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Amount
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Amount Paid
              </th>
              {/* <th scope="col" class="py-3 px-6 uppercase">
                Purchase history
              </th> */}
            </tr>
          </thead>
          <tbody>
            {userData?.map((user, index) => {
              return (
                <tr className="bg-white border-b">

                  <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer" key={index}>
                    {index + 1}
                  </th>

                  <td className="py-4 px-6 cursor-pointer hover:underline">{user?.user_name}</td>
                  <td className="py-4 px-6">
                    {user?.user ? user.user.email : ""}
                  </td>
                  <td className="py-4 px-6">
                    {user?.user ? user.user.username : ""}
                  </td>
                  <td className="py-4 px-6 ">{new Date(user?.created_at).toISOString().split('T')[0]}</td>
                  <td className="py-4 px-6 ">{user?.credits}</td>
                  <td className="py-4 px-6 ">
                    {user?.currency === 'INR' ? (
                      <CurrencyRupee style={{ fontSize: 20 }} />
                    ) : (
                      <AttachMoney style={{ fontSize: 20 }} />
                    )}
                    {user?.amount}
                  </td>
                  <td className="py-4 px-6 ">
                    {user?.currency === 'INR' ? (
                      <CurrencyRupee style={{ fontSize: 20 }} />
                    ) : (
                      <AttachMoney style={{ fontSize: 20 }} />
                    )}
                    {user?.amount_paid}
                  </td>
                  {/* <td className="py-4 px-6 ">{user?.Purchase_history}</td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={userTotal}
        page={clientsPage}
        onPageChange={handleChangePage}
        rowsPerPage={clientsLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};
