import api from "../../../utils/api"
const apiUrl = process.env.REACT_APP_MOCK_RESUME_URL;

export const getSubscriedUser = async(page  , limit ) => {
  try {
    const res = await api.get(
      `/customers/user/get-subscribed-users?sortBy=created_at&orderBy=desc&page=${page}&limit=${limit}`,
      { baseURL:  apiUrl}

    );
    return res;
  } catch (err) {
    return err;
  }
}