import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaidIcon from '@mui/icons-material/Paid';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const fontSize = "30px"

export const adminDashboardHeaders = [
  {
    label: "Clients",
    key: "client",
    icon: <BusinessIcon sx={{color: "skyblue", fontSize}} />,
    color: "#bae6fd"
  },
  {
    label: "Vendors",
    key: "vendor",
    icon: <BusinessIcon sx={{color: "skyblue", fontSize}} />,
    color: "#bae6fd"
  },
  {
    label: "Resources",
    key: "resources",
    icon: <PeopleIcon sx={{color: "#3b82f6", fontSize}}/>,
  },
  {
    label: "Marketplace Jobs",
    key: "marketplaceJobs",
    icon: <HomeRepairServiceIcon sx={{color: "#ec4899", fontSize}}/>,
  },
  {
    label: "Subscribed User",
    key: "subscribe_user",
    icon: <PaidIcon sx={{color: "#f59e0b", fontSize}} />,
    path : "/subscribeduser"
  },
  {
    label : "AI Mock Interviews",
    key: "Ai_Interview",
    icon: <SupportAgentIcon style={{ color: '#3f51b5', fontSize }} />,
    path : '/ai-mockinterview'
  },
  {
    label : "AI Resumes",
    key : "resume_buy_count",
    icon : <InsertDriveFileIcon style={{ color: '#4caf50', fontSize }} />,
    path : "/ai-resume"
  }
]
