import React from "react";
import { useState, useEffect } from "react";
import { TablePagination } from "@mui/material";
import { useQueryParams } from "../../hooks";
import { useOutletContext } from "react-router-dom";
import { getSubscriedUser } from "./helper";

const SubscribedUserList = () => {
  const [userData, setUserData] = useState([]);
  const { getParam, setMultiParam } = useQueryParams();
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [clientsPage, setClientsPage] = useState(parseInt(currentPage) || 0);
  const [clientsLimit, setClientsLimit] = useState(
    parseInt(currentLimit) || 10
  );
  const [userTotal, setUserTotal] = useState(0);
  const context = useOutletContext();

  const handleChangePage = (e, newPage) => {
    setClientsPage(newPage);
    fetchInterviewData(newPage, clientsLimit);
  };

  const handleChangeRowsPerPage = (event) => {
    setClientsLimit(parseInt(event.target.value, 10));
    setClientsPage(0);
    fetchInterviewData(0, parseInt(event.target.value, 10));
  };

  const fetchInterviewData = (page, limit) => {
    getSubscriedUser(page + 1, limit)
      .then((data) => {
        setUserData(data.data.result);
        setUserTotal(data.data.meta.total);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    context(["Dashboard", "Subscribed User"]);
    getSubscriedUser(clientsPage + 1, clientsLimit)
      .then((data) => {
        setUserData(data.data.result);
        setUserTotal(data.data.meta.total);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setMultiParam({ page: clientsPage, pageSize: clientsLimit });
  }, [clientsPage, clientsLimit]);

  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Subscribed Users
        </h3>
      </div>
      <br />
      <div className="overflow-x-auto relative ">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                Sr.
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Company Name
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Email
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Username
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Plan Type
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Next Deduction
              </th>
            </tr>
          </thead>
          <tbody>
            {userData?.map((user, index) => {
              return (
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                    key={index}
                  >
                    {index + 1}
                  </th>

                  <td className="py-4 px-6 cursor-pointer hover:underline">
                    {user?.user ? user.user.company : ""}
                  </td>
                  <td className="py-4 px-6">
                    {user?.user ? user.user.email : ""}
                  </td>
                  <td className="py-4 px-6">
                    {user?.user ? user.user.username : ""}
                  </td>
                  <td className="py-4 px-6 ">
                    {Array.isArray(user?.attributes)
                      ? user?.attributes[0]?.id
                      : user?.attributes?.id
                    }
                  </td>
                  <td className="py-4 px-6 ">
                    {new Date(user?.end_date).toISOString().split("T")[0]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TablePagination
        component="div"
        count={userTotal}
        page={clientsPage}
        onPageChange={handleChangePage}
        rowsPerPage={clientsLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default SubscribedUserList;
